var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"card",class:{'is-shadowless': !_vm.isModal},attrs:{"id":"useItem"}},[_c('div',{staticClass:"card-content"},[_c('header',[_c('div',{staticClass:"is-flex is-justify-content-space-between is-align-items-center mb-2"},[_c('h2',{staticClass:"is-size-6 has-text-primary has-text-weight-bold mr-3"},[_vm._v(_vm._s(_vm.$t('download_report')))]),(_vm.isModal)?_c('div',{on:{"click":function($event){return _vm.$emit('close')}}},[_c('b-icon',{staticClass:"cursor-pointer",attrs:{"icon":"close-thick"}})],1):_vm._e()])]),_c('section',{staticClass:"my-2"},[_c('ValidationObserver',{ref:"form"},[_c('form',{on:{"submit":function($event){$event.preventDefault();return _vm.generateReport.apply(null, arguments)}}},[_c('ValidationProvider',{attrs:{"name":_vm.$t('report_type'),"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-field',{attrs:{"label":_vm.$t('report_type'),"type":errors[0] ? 'is-danger' : '',"message":errors[0]}},[_c('sg-select',{attrs:{"type":"is-secondary is-light","no-outline":"","expanded":"","items":_vm.reportType,"placeholder":((_vm.$t('select')) + " " + (_vm.$t('report_type'))),"label":"label"},on:{"input":_vm.handleChangeType},model:{value:(_vm.form.type),callback:function ($$v) {_vm.$set(_vm.form, "type", $$v)},expression:"form.type"}})],1)]}}])}),_c('ValidationProvider',{attrs:{"name":_vm.$t('farm.block'),"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-field',{attrs:{"label":_vm.$t('farm.block'),"type":errors[0] ? 'is-danger' : '',"message":errors[0]}},[_c('sg-select',{attrs:{"type":"is-secondary is-light","no-outline":"","expanded":"","items":_vm.selectedFarm.blocks,"placeholder":((_vm.$t('select')) + " " + (_vm.$t('farm.block'))),"label":"block_name"},on:{"change":_vm.handleChangeBlock},model:{value:(_vm.form.block),callback:function ($$v) {_vm.$set(_vm.form, "block", $$v)},expression:"form.block"}})],1)]}}])}),_c('ValidationProvider',{attrs:{"name":_vm.$t('farm.pond'),"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-field',{attrs:{"label":_vm.$t('farm.pond'),"type":errors[0] ? 'is-danger' : '',"message":errors[0]}},[_c('sg-select',{attrs:{"type":"is-secondary is-light","items":_vm.filteredPonds,"label":"name","placeholder":((_vm.$t('select')) + " " + (_vm.$t('farm.pond'))),"multiple":"","expanded":""},on:{"input":_vm.handleChangePond},model:{value:(_vm.form.ponds),callback:function ($$v) {_vm.$set(_vm.form, "ponds", $$v)},expression:"form.ponds"}})],1)]}}])}),_c('b-field',[(_vm.form.type !== 'Cycle')?_c('b-checkbox',{attrs:{"disabled":_vm.filteredPonds.length === 0},on:{"input":_vm.handleSelectAllPonds},model:{value:(_vm.to_all_ponds),callback:function ($$v) {_vm.to_all_ponds=$$v},expression:"to_all_ponds"}},[_vm._v(" "+_vm._s(_vm.$t('farm.to_all_ponds'))+" ")]):_vm._e()],1),(_vm.form.type === 'Cycle' && !_vm.loadingCycle)?_c('div',{staticClass:"is-flex overflow-x-auto mb-3"},_vm._l((_vm.form.ponds),function(pond,key){return _c('b-field',{key:key,staticClass:"mr-2"},[_c('div',{staticClass:"control"},[_c('b-button',{staticClass:"has-text-weight-normal",attrs:{"outlined":""}},[_vm._v(_vm._s(pond.name))])],1),_c('div',{staticClass:"control"},[_c('b-dropdown',{attrs:{"aria-role":"list","append-to-body":""},scopedSlots:_vm._u([{key:"trigger",fn:function(ref){
var active = ref.active;
return _c('b-button',{attrs:{"outlined":""}},[_c('div',{staticClass:"is-flex is-align-content-center"},[_c('span',[_vm._v(" "+_vm._s(pond.cycles.length ? (pond.selected_cycle ? pond.selected_cycle.cycle_name : ((_vm.$t('select')) + " " + (_vm.$t('cycle.cycle')))) : ((_vm.$t('no')) + " " + (_vm.$t('cycle.cycle'))))+" ")]),_c('b-icon',{attrs:{"icon":active ? 'menu-up-outline' : 'menu-down-outline'}})],1)])}}],null,true)},_vm._l((pond.cycles),function(cycle,k){return _c('b-dropdown-item',{key:k,attrs:{"aria-role":"listitem"},on:{"click":function($event){pond.selected_cycle = cycle}}},[_vm._v(" "+_vm._s(cycle.cycle_name)+" ")])}),1)],1)])}),1):_vm._e(),(_vm.form.type !== 'Cycle')?_c('div',[(_vm.form.type === 'Daily')?_c('ValidationProvider',{attrs:{"name":_vm.$t('date'),"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-field',{attrs:{"label":_vm.$t('date'),"type":errors[0] ? 'is-danger' : '',"message":errors[0]}},[_c('b-datepicker',{attrs:{"placeholder":_vm.$t('date'),"icon":"calendar-text","locale":"id-ID"},model:{value:(_vm.form.date),callback:function ($$v) {_vm.$set(_vm.form, "date", $$v)},expression:"form.date"}})],1)]}}],null,false,3556349567)}):_c('ValidationProvider',{attrs:{"name":_vm.$t('date'),"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-field',{attrs:{"label":_vm.$t('date'),"type":errors[0] ? 'is-danger' : '',"message":errors[0]}},[_c('b-datepicker',{attrs:{"placeholder":_vm.$t('date'),"icon":"calendar-text","locale":"id-ID","range":""},model:{value:(_vm.form.date_range),callback:function ($$v) {_vm.$set(_vm.form, "date_range", $$v)},expression:"form.date_range"}})],1)]}}],null,false,3784457238)})],1):_vm._e(),_c('div',{staticClass:"has-text-right"},[_c('b-button',{attrs:{"type":"is-secondary is-light"},on:{"click":function($event){return _vm.$emit('close')}}},[_vm._v(_vm._s(_vm.$t('cancel')))]),_c('b-button',{staticClass:"ml-2",attrs:{"type":"is-primary is-gradient","native-type":"submit"}},[_vm._v("Download")])],1)],1)])],1)])])}
var staticRenderFns = []

export { render, staticRenderFns }