<template>
  <div class="card" :class="{'is-shadowless': !isModal}" id="useItem">
    <div class="card-content">
      <header>
        <div class="is-flex is-justify-content-space-between is-align-items-center mb-2">
          <h2 class="is-size-6 has-text-primary has-text-weight-bold mr-3">{{ $t('download_report') }}</h2>
          <div @click="$emit('close')" v-if="isModal">
            <b-icon class="cursor-pointer" icon="close-thick"/>
          </div>
        </div>
      </header>
      <section class="my-2">
        <ValidationObserver ref="form">
          <form @submit.prevent="generateReport">
            <ValidationProvider :name="$t('report_type')" rules="required" v-slot="{ errors }">
              <b-field :label="$t('report_type')" :type="errors[0] ? 'is-danger' : ''" :message="errors[0]">
                <sg-select type="is-secondary is-light" no-outline expanded
                           :items="reportType"
                           :placeholder="`${$t('select')} ${$t('report_type')}`"
                           label="label"
                           @input="handleChangeType"
                           v-model="form.type"></sg-select>
              </b-field>
            </ValidationProvider>

            <ValidationProvider :name="$t('farm.block')" rules="required" v-slot="{ errors }">
              <b-field :label="$t('farm.block')" :type="errors[0] ? 'is-danger' : ''" :message="errors[0]">
                <sg-select type="is-secondary is-light" no-outline expanded :items="selectedFarm.blocks"
                           :placeholder="`${$t('select')} ${$t('farm.block')}`"
                           label="block_name"
                           @change="handleChangeBlock"
                           v-model="form.block"></sg-select>
              </b-field>
            </ValidationProvider>

            <ValidationProvider :name="$t('farm.pond')" rules="required" v-slot="{ errors }">
              <b-field :label="$t('farm.pond')" :type="errors[0] ? 'is-danger' : ''" :message="errors[0]">
                <sg-select type="is-secondary is-light" v-model="form.ponds" :items="filteredPonds"
                           label="name"
                           @input="handleChangePond"
                           :placeholder="`${$t('select')} ${$t('farm.pond')}`"
                           multiple expanded/>
<!--                <sg-select type="is-secondary is-light" v-model="form.pond" :items="filteredPonds"-->
<!--                           label="name"-->
<!--                           @input="handleChangePondCycle"-->
<!--                           :placeholder="`${$t('select')} ${$t('farm.pond')}`"-->
<!--                           v-else-->
<!--                           expanded/>-->
              </b-field>
            </ValidationProvider>

            <b-field>
              <b-checkbox
                  v-if="form.type !== 'Cycle'"
                  :disabled="filteredPonds.length === 0"
                  v-model="to_all_ponds" @input="handleSelectAllPonds">
                {{ $t('farm.to_all_ponds') }}
              </b-checkbox>
            </b-field>

            <div class="is-flex overflow-x-auto mb-3" v-if="form.type === 'Cycle' && !loadingCycle">
              <b-field class="mr-2" v-for="(pond, key) in form.ponds" :key="key">
                <div class="control">
                  <b-button outlined class="has-text-weight-normal">{{ pond.name }}</b-button>
                </div>
                <div class="control">
                  <b-dropdown aria-role="list" append-to-body>
                    <b-button outlined slot="trigger" slot-scope="{ active }">
                      <div class="is-flex is-align-content-center">
                        <span>
                          {{ pond.cycles.length ? (pond.selected_cycle ? pond.selected_cycle.cycle_name : `${$t('select')} ${$t('cycle.cycle')}`) : `${$t('no')} ${$t('cycle.cycle')}` }}
                        </span>
                        <b-icon :icon="active ? 'menu-up-outline' : 'menu-down-outline'"></b-icon>
                      </div>
                    </b-button>

                    <b-dropdown-item aria-role="listitem" v-for="(cycle, k) in pond.cycles" :key="k" @click="pond.selected_cycle = cycle">
                      {{ cycle.cycle_name }}
                    </b-dropdown-item>
                  </b-dropdown>
                </div>
              </b-field>
            </div>

            <div v-if="form.type !== 'Cycle'">
              <ValidationProvider :name="$t('date')" rules="required" v-slot="{ errors }" v-if="form.type === 'Daily'">
                <b-field :label="$t('date')" :type="errors[0] ? 'is-danger' : ''" :message="errors[0]">
                  <b-datepicker
                      :placeholder="$t('date')"
                      icon="calendar-text"
                      locale="id-ID"
                      v-model="form.date">
                  </b-datepicker>
                </b-field>
              </ValidationProvider>
              <ValidationProvider :name="$t('date')" rules="required" v-slot="{ errors }" v-else>
                <b-field :label="$t('date')" :type="errors[0] ? 'is-danger' : ''" :message="errors[0]">
                  <b-datepicker
                      :placeholder="$t('date')"
                      icon="calendar-text"
                      locale="id-ID"
                      range
                      v-model="form.date_range">
                  </b-datepicker>
                </b-field>
              </ValidationProvider>
            </div>

            <div class="has-text-right">
              <b-button type="is-secondary is-light" @click="$emit('close')">{{ $t('cancel') }}</b-button>
              <b-button type="is-primary is-gradient" native-type="submit" class="ml-2">Download</b-button>
            </div>
          </form>
        </ValidationObserver>
      </section>
    </div>
  </div>
</template>

<script>
import SgSelect from "@/components/Sg/SgSelect";

export default {
  name: "GenerateReportForm",
  components: {SgSelect},
  props: {
    isModal: {
      type: Boolean,
      default: false
    },
    warehouse: {
      type: Object
    }
  },
  computed: {
    selectedFarm() {
      return this.$store.state.farm.selectedFarm
    },
  },
  watch: {
    'form.ponds': {
      deep: true,
      handler(value) {
        if (value.length > 0 && value.length === this.filteredPonds.length) {
          this.to_all_ponds = true
        } else {
          this.to_all_ponds = false
        }
      }
    }
  },
  data() {
    return {
      form: {
        block: null,
        ponds: [],
        type: 'Cycle',
        date_range: [],
        date: null,
        pond: null,
        cycle: null,
      },
      to_all_ponds: false,

      data: [],

      reportType: ['Cycle', 'Monthly', 'Weekly', 'Daily'],

      loadingCycle: false,

      filteredPonds: [],
    }
  },
  methods: {
    async handleChangeBlock() {
      this.form.ponds = []
      this.form.pond = null

      if (this.form.block) {
        this.$loading()
        this.loadingCycle = true

        this.filteredPonds = this.selectedFarm.ponds.filter(e => e.block_id === this.form.block.block_id)

        if (this.filteredPonds.length) {
          if (this.form.type === 'Cycle') {
            this.form.ponds = [this.filteredPonds[0]]
            this.form.pond = this.filteredPonds[0]
          } else {
            this.form.ponds = this.filteredPonds
            this.to_all_ponds = true
          }

          for (let i = 0; i < this.filteredPonds.length; i++) {
            await this.$store.dispatch('farm/getCycleOverview', {
              pond: this.filteredPonds[i]
            })
          }
        }

        this.$loading(false)
        this.loadingCycle = false

        this.handleChangePond()
      }
    },

    handleChangePondCycle() {
      this.form.ponds = [this.form.pond]
      this.handleChangePond()
    },

    handleChangePond() {
      this.form.ponds = this.form.ponds.map(e => {
        if (!e.selected_cycle && e.cycles) {
          e.selected_cycle = e.cycles[0]
        }
        return e
      }).sort((a, b) => a.name.localeCompare(b.name, undefined, {
        sensitivity: 'base',
        numeric: true,
      }))
    },

    handleSelectAllPonds() {
      if (this.to_all_ponds) {
        this.form.ponds = this.filteredPonds.filter(e => e)
      } else {
        this.form.ponds = []
      }
    },

    handleChangeType(value) {
      this.form.date = null
      this.form.date_range = []
      if (value === 'Daily') this.form.date = new Date()
      if (value === 'Monthly') {
        let date = this.$moment().add({months: -1, days: 1}).toDate()
        this.form.date_range = [
          date,
          new Date(),
        ]
      }
      if (value === 'Weekly') {
        let date = this.$moment().add({weeks: -1, days: 1}).toDate()
        this.form.date_range = [
          date,
          new Date(),
        ]
      }
      this.handleChangeBlock()
    },

    generateReport() {
      this.$refs['form'].validate().then(success => {
        if (success) {
          let date = null

          let ponds = this.form.ponds.map(e => {
            let res = {
              pond_id: e.pond_id,
              name: e.name,
              area: e.area,
            }
            if (this.form.type === 'Cycle') res.cycle_id = e.selected_cycle ? e.selected_cycle.cycle_id : null
            return res
          })

          if (this.form.type === 'Cycle') ponds = ponds.filter(e => e.cycle_id)
          else date = this.form.type === 'Daily' ? [this.form.date] : this.form.date_range

          let payload = {
            ponds: JSON.stringify(ponds),
            type: this.form.type,
          }

          if (date) payload.date = JSON.stringify(date.map(e => this.$timestamp(e)))

          let router = this.$router.resolve({
            path: `/farm/${this.selectedFarm.farm_id}/report`,
            query: payload
          })

          window.open(router.href, '_blank')
        }
      })
    },
  }
}
</script>

<style scoped lang="scss">
.card#useItem {
  width: 550px !important;
  max-width: 100%;
}
</style>

